import { Link } from "react-router-dom";
import NotFoundImg from "../assets/images/404 Error-rafiki.svg";
const NotFound = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center">
        <img
          src={NotFoundImg}
          alt="500 Internal Server Error"
          className="w-full max-w-xs md:max-w-md lg:max-w-lg xl:max-w-2xl"
        />
        <p className="text-2xl md:text-3xl font-medium text-gray-600 mt-4">
          Oops! Page not found.
        </p>
        <p className="text-gray-500 mt-2">
          The page you're looking for doesn't exist or has been moved.
        </p>
        <Link
          to="/"
          className="mt-6 inline-block px-8 py-3 text-white bg-[#461A55]/90 rounded-lg hover:bg-[#461A55] transition duration-300"
        >
          Go back home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
