import React from "react";

const TermsOfService = () => {
  return (
    <div className="bg-white min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl container mx-auto overflow-hidden">
        <div className="px-6 py-8">
          <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">
            Terms of Service for Megs Kombat
          </h1>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              1. Acceptance of Terms
            </h2>
            <p className="text-gray-600 mb-4">
              By accessing or using Megs Kombat, you agree to comply with these Terms of Service. If you do not agree, please do not use our services.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              2. Services Provided
            </h2>
            <p className="text-gray-600 mb-4">
              Megs Kombat offers a Play-to-Earn platform where users can connect their YouTube accounts, earn MEGS Coin based on channel performance, complete daily tasks, and invite friends for rewards.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              3. User Responsibilities
            </h2>
            <p className="text-gray-600 mb-4">
              You agree to provide accurate information and maintain the security of your account. You are responsible for all activities that occur under your account.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              4. Data Collection and Usage
            </h2>
            <p className="text-gray-600 mb-4">
              We collect data as outlined in our <a href="https://megskombat.com/privacypolicy" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Privacy Policy</a>, which describes how we handle your personal information.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              5. Termination
            </h2>
            <p className="text-gray-600 mb-4">
              We reserve the right to terminate or suspend your access to our services at our discretion, without prior notice, for conduct that we believe violates these Terms.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              6. Modifications
            </h2>
            <p className="text-gray-600 mb-4">
              We may revise these Terms from time to time. Any changes will be posted on this page, and your continued use of the services will signify your acceptance of the revised Terms.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              7. Contact Information
            </h2>
            <p className="text-gray-600 mb-4">
              For any questions about these Terms, please contact us at info@megskombat.com.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              8. Governing Law
            </h2>
            <p className="text-gray-600 mb-4">
              These Terms shall be governed by and construed in accordance with the laws of UAE.
            </p>
          </section>

          <p className="text-sm text-gray-500 text-center">
           Last updated: September 19, 2024         
         </p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
