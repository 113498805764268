import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="bg-white  min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl container mx-auto  overflow-hidden">
        <div className="px-6 py-8">
          <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">
            Privacy Policy for Megs Kombat
          </h1>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              1. Introduction
            </h2>
            <p className="text-gray-600 mb-4">
              Welcome to Megs Kombat. We respect your privacy and are committed
              to protecting your personal data. This privacy policy will inform
              you about how we look after your personal data when you visit our
              website and participate in our Play-to-Earn experience, and tell
              you about your privacy rights and how the law protects you.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              2. YouTube API Services
            </h2>
            <p className="text-gray-600 mb-4">
              Megs Kombat uses the YouTube API Services to access certain data
              from your YouTube channel. Our use of information received from
              Google APIs will adhere to the
              <a
                href="https://developers.google.com/terms/api-services-user-data-policy"
                className="text-blue-600 hover:text-blue-800"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Google API Services User Data Policy
              </a>
              , including the Limited Use requirements.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              3. Data We Collect
            </h2>
            <p className="text-gray-600 mb-4">
              When you connect your YouTube channel to Megs Kombat, we collect
              and process the following read-only information:
            </p>
            <ul className="list-disc list-inside text-gray-600 mb-4">
              <li>Your YouTube channel ID</li>
              <li>Number of subscribers</li>
              <li>Total view count</li>
              <li>Channel creation date</li>
              <li>Public video metadata</li>
            </ul>
            <p className="text-gray-600 mb-4">
              We only request read-only access
              (https://www.googleapis.com/auth/youtube.readonly) to your YouTube
              data. This means we cannot make any changes to your YouTube
              channel or post on your behalf.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              4. How We Use Your Data
            </h2>
            <p className="text-gray-600 mb-4">
              We use the collected data solely to:
            </p>
            <ul className="list-disc list-inside text-gray-600 mb-4">
              <li>
                Calculate and distribute MEGS Coin earnings based on your
                channel's performance
              </li>
              <li>
                Verify eligibility for participation in our Play-to-Earn program
              </li>
              <li>Improve our services and user experience</li>
              <li>Prevent fraud and ensure fair play</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              5. Data Protection
            </h2>
            <p className="text-gray-600 mb-4">
              We implement appropriate technical and organizational measures to
              ensure a level of security appropriate to the risk, including:
            </p>
            <ul className="list-disc list-inside text-gray-600 mb-4">
              <li>Encryption of personal data</li>
              <li>Regular security assessments</li>
              <li>Access controls and authentication measures</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              6. Your Rights
            </h2>
            <p className="text-gray-600 mb-4">You have the right to:</p>
            <ul className="list-disc list-inside text-gray-600 mb-4">
              <li>Request access to your personal data</li>
              <li>Request correction of your personal data</li>
              <li>Request erasure of your personal data</li>
              <li>Object to processing of your personal data</li>
              <li>Request restriction of processing your personal data</li>
              <li>Request transfer of your personal data</li>
              <li>Withdraw your consent at any time</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              7. Revoking Access
            </h2>
            <p className="text-gray-600 mb-4">
              You can revoke Megs Kombat's access to your YouTube data at any
              time by visiting the
              <a
                href="https://security.google.com/settings/security/permissions"
                className="text-blue-600 hover:text-blue-800"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Google Security Settings
              </a>{" "}
              page and removing access for Megs Kombat.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              8. Changes to This Privacy Policy
            </h2>
            <p className="text-gray-600 mb-4">
              We may update our privacy policy from time to time. We will notify
              you of any changes by posting the new privacy policy on this page
              and updating the "Last updated" date.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              9. Contact Us
            </h2>
            <p className="text-gray-600 mb-4">
              If you have any questions about this privacy policy or our data
              practices, please contact us at:
            </p>
            <p className="text-gray-600">
              Email: info@megskombat.com
              <br />
            </p>
          </section>
        </div>
        <div className="bg-gray-50 px-6 py-4">
          <p className="text-sm text-gray-500 text-center">
            Last updated: September 19, 2024
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
