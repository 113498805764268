import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "../layout/Layout";
import Hero from "../pages/Hero";
import PrivacyPolicy from "../pages/PrivacyPolice";
import NotFound from "../pages/NotFound";
import TermsOfService from "../pages/TermsOfService";

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" index element={<Hero />} />
      </Route>
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      <Route path="/TermsOfService" element={<TermsOfService />} />
      <Route path="*" element={<NotFound />} />

    </Routes>
  );
};

export default AppRoutes;
